
import Modal from "@/components/Modal.vue";
import { Select, Option } from "element-ui";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit,
} from "vue-property-decorator";
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from "vuex";
import { eventHandler, translations } from "@/mixins";
import { CustomFields } from "@/services/SOLO";
import { AttrCustomField, CustomField } from "@/models";

interface CustomFieldItemObject {
  id: string;
  value: string;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [eventHandler, translations],
})
export default class LocationCustomFieldsModal extends Vue {
  open: Boolean = false;
  saving: Boolean = false;

  @Prop() customField!: CustomFieldItemObject;
  @Prop() customFields!: Array<CustomField>;
  @Prop() locationId!: string;

  waiting!: Function;
  restore!: Function;
  translate!: Function;
  $notify: any;

  submit() {
    if (this.saving) {
      return;
    }

    this.saving = true;
    let payload = {
      entity: "location",
      "entity-id": this.locationId,
      value: this.customField.value,
    };

    CustomFields.postData(this.customField.id, payload)
      .then((response: any) => {
        this.$emit("locationCustomField:saved", {
          "custom-field": response.data.data.attributes.label,
          "custom-field-id": this.customField.id,
          value: payload.value,
        });
        this.saving = false;
        this.open = false;
        this.$notify({
          title: this.translate("RECORDS SAVED!"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Record have been saved successfully"),
          type: "success",
          icon: "fas fa-check",
        });
      })
      .catch((err: any) => {
        this.saving = false;
        this.$notify({
          title: this.translate("SYSTEM ERROR!"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Something went wrong, please try again!"),
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }
}
